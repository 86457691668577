<template>
    <div class="login_container">
        <div class="login">
            <h2>bicigesu</h2>
            <el-form class="from" :label-position="labelPosition" label-width="100px" :model="loginData" @keyup.enter="sub_btn">
                <el-form-item :label="showText.phoneNum" class="login-from-item">
                    <el-input :placeholder="showText.phoneNum" @keyup.enter="sub_btn" v-model="loginData.userName"></el-input>
                </el-form-item>
                <el-form-item :label="showText.password" class="login-from-item">
                    <el-input :placeholder="showText.password" @keyup.enter="sub_btn" show-password
                        v-model="loginData.password"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-button @click="sub_btn" type="primary" >
                        ᠨᠡᠪᠲᠡᠷᠡᠬᠦ
                    </el-button>
                    <el-button @click="register_btn" type="primary">
                         ᠳᠠᠰᠠᠯᠠᠬᠣ
                    </el-button>
                </el-form-item>
                <el-form-item>
                    <!-- <el-button class="wx-btn"></el-button> -->
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import {loginDateGet,saveBrowserData} from '../http/login.js';
import { reactive } from '@vue/reactivity';
import {router} from '../router/index.js';
import { ElMessage } from 'element-plus';
import getBrowserInfo from '../assets/js/browser.js';
export default {
    name: 'LoginView',
    setup(){
        let showText={
            phoneNum: " ᠭᠠᠷ ᠤᠲᠠᠰᠤᠨ ᠨᠣᠮᠧᠷ ",
            password:" ᠨᠢᠭᠣᠴᠠ    ᠦᠭᠡ "
        }
        let loginData = reactive({
            userName:"",
            password:""
        });
        const saveBrowser=()=>{
            let browserData = getBrowserInfo();
            console.log(browserData);
            saveBrowserData(browserData).then(res=>{
                console.log(res);
            })
        }
        let sub_btn = ()=>{
            if(!loginData.userName||!loginData.password){
                ElMessage.error("账号或密码不能为空");
                return;
            }
            loginDateGet(loginData).then(res=>{
                saveBrowser();
                if(res.code === '0'){
                    let token = res.data.token;
                    let refleshToken = res.data.refleshToken;
                    sessionStorage.setItem("token",token);
                    sessionStorage.setItem("refleshToken",refleshToken);
                    router.push('/index')
                    return;
                }
                ElMessage.error(res.msg);
            })
        };
        let register_btn = ()=>{
            router.push('/register')
        }
        return {
            loginData,
            sub_btn,
            register_btn,
            showText
        };
    }
 }
</script>
<style scoped>
    .login_container{
        overflow:hidden;
        height: 100vh;
        background-image:url("../assets/image/login.jpg");
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .login_container::before{
        content: "";
        background: rgba(0, 0, 0, 0.7);
    }
    .login{
        width:450px ;
        border: 1px solid #efefef60;
        border-radius: 10px;
        background: rgba(22, 22, 22, 0.6);
        padding: 10px;
    }
    .from{
        margin: 0 20px;
    }
    .wx-btn{
        width: 30px;
        height: 30px;
        background-image: url(http://192.168.0.186:8080/img/weixin-icon.ddcc26d3.svg);
        background-size: 100% 100%;
        background-color: #121210;
        border: 0px;
    }
    .wx-btn:hover {
        background-color: #2c2c2c;
    }
    .wx-btn:focus {
          background-color: #121210;
    } 
    .wx-btn:active {
        background-color: #000000; 
    }
    .label  {
        display: flex;
        flex-direction: row;
        padding-right: 40px;
    }
    .login-from-item ::v-deep .el-form-item__label {
        color: rgb(245, 239, 239);
        font-size: 22px;
        font-weight: 600;
        writing-mode: vertical-lr;
        height: 45px;
        padding-top: 4px;
    }
    .login-from-item ::v-deep .el-input__inner{
        height: 45px;
    }
    h2{ 
        margin-top: 20px;
        text-align: center;
        font-size: 34px;
        color: rgb(233, 233, 233);
    }
   
</style>